import React, { useState } from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import { Outlet } from "react-router-dom";

const Layout = () => {
  const [isOpen, setIsopen] = useState(true);

  return (
    <div className="container-fluid d-flex">
      <Sidebar isOpen={isOpen} setIsopen={setIsopen} />
      <div
        className={`header-section w-100 ${isOpen ? "non-active" : "active"}`}
      >
        <Header isOpen={isOpen} setIsopen={setIsopen} />
        <div className="body-content-section">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
